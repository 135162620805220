

table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: none;
    text-align: center;
}

thead tr {
    background-image: linear-gradient(180deg, var(--primary-color) , var(--primary-color-very-dark));
    background-color: var(--primary-color-dark);
    border-bottom: 1px solid var(--nuetral-dark);
}

th {
    padding: 2rem 0.5rem;
    font-size: 0;
    color: var(--nuetral-light);
}

th i{
    font-size: 2rem;
}

td{
    padding: 2rem 0.5rem;
}



tbody tr:nth-child(odd) {
    background-image: linear-gradient(180deg, var(--primary-color-very-light) , var(--primary-color-light));
    background-color: var(--primary-color-light);
    border-bottom: 1px solid var(--nuetral-light);

}

tbody tr:nth-child(even) {
    border-bottom: 1px solid var(--nuetral-light);
    background-color: var(--primary-color-very-light);
}

.configs__copy-btn{
    position: relative;
}

.configs__copy-btn::before{
    position: absolute;
    z-index: 10;
    content: "کپی شد";
    top: 100%;
    left: 50%;
    transform: translate(-50%, -2rem);
    background-color: var(--nuetral-dark);
    color: var(--primary-color-light);
    padding: 0.5em 1em;
    border-radius: 20px;
    font-family: 'iranyekan', 'sans-serif';
    font-weight: 400;
    font-size: 1rem;
    width: max-content;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s , visibility 0.3s, transform 0.3s;
}

.configs__copy-btn--copied::before{
    visibility: visible;
    transform: translate(-50%, 0.75rem);
    opacity: 1;
}


@media only screen and (min-width: 62em ) {

    td, th {
        padding: 2.5rem;
    }


    th i{
        font-size: 2.7rem;
    }
}


