html {
  --primary-color: #3680fa;
  --primary-color-light: #c4d8fa;
  --primary-color-very-light: #dce6f5;
  --primary-color-dark: #2666cf;
  --primary-color-very-dark: #174ea8;
  --nuetral-dark: #343434;
  --nuetral-dark-light: #525151;
  --nuetral-light: #fff;
  --nuetral-light-transparent: #fffa;
  font-size: 62.5%;
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  color: var(--nuetral-dark);
  background-color: var(--nuetral-light);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
}

a {
  text-decoration: none;
}

.page {
  width: 100%;
  min-height: 100vh;
  background-color: var(--primary-color-very-light);
}

@media only screen and (min-width: 62em) {
  body {
    font-size: 1.6rem;
  }

  .page {
    width: 90%;
    max-width: 140rem;
    box-shadow: 0 0 10px var(--primary-color-dark);
    margin: 0 auto;
  }
}

@font-face {
  font-family: icomoon;
  src: url("icomoon.2ad02fa3.woff") format("woff"), url("icomoon.c71fd6be.ttf") format("truetype"), url("icomoon.8104cb33.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  speak: never;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-family: icomoon !important;
}

.icon-trash:before {
  content: "";
}

.icon-v2ray:before {
  content: "";
}

.icon-check:before {
  content: "";
}

.icon-tag:before {
  content: "";
}

.icon-list:before {
  content: "";
}

.icon-download:before {
  content: "";
}

.icon-gear:before {
  content: "";
}

.icon-app:before {
  content: "";
}

.icon-copy:before {
  content: "";
}

.icon-mac:before {
  content: "";
}

.icon-android:before {
  content: "";
}

.icon-windows:before {
  content: "";
}

.icon-ios:before {
  content: "";
}

.icon-linux:before {
  content: "";
}

.icon-apps:before {
  content: "";
}

.icon-info:before {
  content: "";
}

.icon-eye:before {
  content: "";
}

.icon-eye-blocked:before {
  content: "";
}

.tabs {
  flex-wrap: wrap;
  justify-content: space-around;
  display: flex;
  position: relative;
}

.tabs__radio {
  display: none;
}

.tabs__heading {
  --translateY: 0;
  width: 50%;
  z-index: 10;
  background-image: linear-gradient(180deg, var(--primary-color), var(--primary-color-very-dark));
  background-color: var(--primary-color-very-dark);
  color: var(--nuetral-light-transparent);
  border-top: 1px solid var(--primary-color-very-dark);
  cursor: pointer;
  text-align: center;
  transform: translateY(var(--translateY));
  flex-grow: 1;
  padding: 2rem .5rem;
  font-size: 0;
  transition: transform .3s;
  position: fixed;
  bottom: 0;
  left: 0;
  -webkit-appearance: none !important;
  -webkit-tap-highlight-color: #0000 !important;
  outline: none !important;
}

.tabs__heading i {
  font-size: 2rem;
}

.tabs__configs {
  right: 0;
}

.tabs__apps {
  left: 0;
}

.tabs__content {
  width: 100%;
  opacity: 0;
  height: 0;
  order: 1;
  transition: height, opacity 1s;
  overflow: hidden;
}

.tabs__radio:checked + .tabs__heading + .tabs__content {
  opacity: 1;
  height: auto;
}

.tabs__radio:checked + .tabs__heading {
  color: var(--nuetral-light);
}

.tabs__line {
  --visibility: visible;
  --translate-x: -100%;
  --width: 0;
  z-index: 10;
  transform: translateX(var(--translate-x));
  width: var(--width);
  height: 5px;
  background-color: var(--nuetral-light);
  visibility: var(--visibility);
  transition: transform .3s, visibility .1s;
  position: fixed;
  bottom: 0;
  left: 100%;
}

@media only screen and (min-width: 62em) {
  .tabs__heading {
    position: initial;
    padding: 2.5rem;
    transform: none;
  }

  .tabs__heading i:before {
    font-size: 2.3rem;
  }

  .tabs__line {
    --top: -1000px;
    top: var(--top);
    visibility: visible;
    position: absolute;
  }
}

.time {
  --time-height: 3rem;
  --time-width: 2rem;
  background-image: linear-gradient(180deg, var(--nuetral-dark-light), var(--nuetral-dark));
  background-color: var(--nuetral-dark);
  color: var(--nuetral-light);
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 1rem .25rem;
  font-size: 1.5rem;
  display: flex;
}

.time__number {
  display: flex;
  position: relative;
}

.time > .time__number + .time__number {
  margin-right: 1.5rem;
}

.time__two, .time__one {
  background-image: linear-gradient(180deg, var(--primary-color) 0%, var(--primary-color) 50%, var(--primary-color-very-dark) 50%, var(--primary-color-very-dark) 100%);
  background-color: var(--primary-color-dark);
  width: var(--time-width);
  height: var(--time-height);
  border-radius: 20%;
  margin-right: .4rem;
  position: relative;
  overflow: hidden;
}

.time__next, .time__current {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.time__current {
  line-height: var(--time-height);
  transition: all;
  transform: translateY(0);
}

.time__next {
  transform: translateY(calc(-1 * var(--time-height)));
  transition: all;
}

.time__current--anim {
  transform: translateY(var(--time-height));
  transition: transform .4s;
}

.time__next--anim {
  line-height: var(--time-height);
  transition: transform .4s;
  transform: translateY(0);
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  text-align: center;
  border: none;
}

thead tr {
  background-image: linear-gradient(180deg, var(--primary-color), var(--primary-color-very-dark));
  background-color: var(--primary-color-dark);
  border-bottom: 1px solid var(--nuetral-dark);
}

th {
  color: var(--nuetral-light);
  padding: 2rem .5rem;
  font-size: 0;
}

th i {
  font-size: 2rem;
}

td {
  padding: 2rem .5rem;
}

tbody tr:nth-child(2n+1) {
  background-image: linear-gradient(180deg, var(--primary-color-very-light), var(--primary-color-light));
  background-color: var(--primary-color-light);
  border-bottom: 1px solid var(--nuetral-light);
}

tbody tr:nth-child(2n) {
  border-bottom: 1px solid var(--nuetral-light);
  background-color: var(--primary-color-very-light);
}

.configs__copy-btn {
  position: relative;
}

.configs__copy-btn:before {
  z-index: 10;
  content: "کپی شد";
  background-color: var(--nuetral-dark);
  color: var(--primary-color-light);
  width: max-content;
  visibility: hidden;
  opacity: 0;
  border-radius: 20px;
  padding: .5em 1em;
  font-family: iranyekan, "sans-serif";
  font-size: 1rem;
  font-weight: 400;
  transition: opacity .3s, visibility .3s, transform .3s;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -2rem);
}

.configs__copy-btn--copied:before {
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, .75rem);
}

@media only screen and (min-width: 62em) {
  td, th {
    padding: 2.5rem;
  }

  th i {
    font-size: 2.7rem;
  }
}

.btn {
  cursor: pointer;
  box-shadow: 0 1px 5px var(--primary-color-dark);
  background-color: var(--primary-color-very-light);
  color: var(--nuetral-dark);
  border: none;
  border-radius: 50%;
  padding: 1.4rem;
  font-size: 1.6rem;
  transition: color .3s, background-color .3s;
  display: inline-block;
  -webkit-appearance: none !important;
  -webkit-tap-highlight-color: #0000 !important;
  outline: none !important;
}

.btn i {
  float: left;
}

.btn--dark-shadow {
  box-shadow: 0 1px 5px #343434;
}

.btn--dark-shadow:active {
  color: var(--nuetral-light);
  background-color: var(--nuetral-dark);
}

@media (hover: hover) and (pointer: fine) {
  .btn:hover {
    color: var(--nuetral-light);
    background-color: var(--nuetral-dark);
  }
}

@font-face {
  font-family: iranyekan;
  src: url("iranyekanwebregularfanum.ee2218cf.woff2") format("woff2"), url("iranyekanwebregularfanum.c62c7245.woff") format("woff"), url("iranyekanwebregularfanum.78f4c2db.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

.apps {
  flex-wrap: wrap;
  justify-content: space-around;
  display: flex;
  position: relative;
}

.apps__radio {
  display: none;
}

.apps__heading {
  text-align: center;
  background-image: linear-gradient(180deg, var(--primary-color), var(--primary-color-very-dark));
  background-color: var(--nuetral-light);
  color: var(--nuetral-light-transparent);
  cursor: pointer;
  flex-grow: 1;
  padding: 2rem .5rem;
  font-size: 0;
  transition: color .5s;
  -webkit-appearance: none !important;
  -webkit-tap-highlight-color: #0000 !important;
  outline: none !important;
}

.apps__heading i {
  font-size: 2.3rem;
}

.apps__radio:checked + .apps__heading {
  color: var(--nuetral-light);
}

.apps__content {
  width: 100%;
  visibility: hidden;
  opacity: 0;
  flex-wrap: wrap;
  order: 1;
  justify-content: center;
  margin: -.5rem;
  padding: 3rem 1rem;
  transition: transform .5s, opacity .5s;
  display: flex;
  position: absolute;
  transform: translateY(-50px);
}

.apps__radio:checked + .apps__heading + .apps__content {
  opacity: 1;
  position: initial;
  visibility: visible;
  transform: translateY(0);
}

.apps__content:after {
  content: "";
  flex: auto;
}

.apps__app {
  background-color: var(--primary-color-very-light);
  box-shadow: 0 1px 5px var(--primary-color-dark);
  color: var(--nuetral-dark);
  text-align: center;
  border-radius: 10%;
  flex-direction: column;
  flex-basis: calc(33.33% - 1rem);
  justify-content: center;
  align-items: center;
  margin: .5rem;
  padding: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  text-decoration: none;
  display: flex;
}

.apps__photo {
  max-width: 60%;
  border: 1px solid var(--nuetral-dark);
  border-radius: 20%;
  margin-bottom: 1rem;
}

.apps__line {
  --translate-x: -100%;
  --top: -1000px;
  --width: 0;
  z-index: 10;
  top: var(--top);
  transform: translateX(var(--translate-x));
  width: var(--width);
  height: 5px;
  background-color: var(--nuetral-light);
  border-bottom: 1px solid var(--primary-color-very-dark);
  transition: transform .3s;
  position: absolute;
  left: 100%;
}

@media only screen and (max-width: 21.7em) {
  .apps__app {
    flex-basis: calc(50% - 1rem);
  }
}

@media only screen and (min-width: 37.5em) {
  .apps__content {
    margin: -1rem;
    padding: 3rem 2rem;
  }

  .apps__app {
    flex-basis: calc(25% - 2rem);
    margin: 1rem;
  }
}

@media only screen and (min-width: 62em) {
  .apps__content {
    margin: -1.5rem;
  }

  .apps__app {
    flex-basis: calc(20% - 3rem);
    margin: 1.5rem;
    font-size: 1.4rem;
  }

  .apps__photo {
    margin-bottom: 2rem;
  }

  .apps__heading {
    text-align: center;
    background-image: linear-gradient(180deg, var(--primary-color), var(--primary-color-very-dark));
    background-color: var(--nuetral-light);
    color: var(--nuetral-light-transparent);
    flex-grow: 1;
    padding: 2.5rem;
    font-size: 0;
    transition: color .5s;
  }

  .apps__heading i {
    font-size: 2.7rem;
  }
}

.configs__info-name {
  justify-content: flex-start;
  align-items: center;
  display: flex;
  position: relative;
}

.configs__info {
  --info-font-size: 2.1rem;
  cursor: pointer;
  margin-left: auto;
  position: relative;
  -webkit-appearance: none !important;
  -webkit-tap-highlight-color: #0000 !important;
  outline: none !important;
}

.configs__name-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.configs__info-icon {
  font-size: var(--info-font-size);
}

.configs__tooltip, .configs__tooltip:after {
  --tooltip-scale: 0;
  opacity: 0;
  z-index: 9;
  transition: all .3s;
  position: absolute;
}

.configs__tooltip {
  width: max-content;
  max-width: 66.6667vw;
  transform: translate(-100%, -100%) scale(var(--tooltip-scale));
  transform-origin: 100% 100%;
  background-color: var(--nuetral-dark);
  color: #fff;
  text-align: right;
  border-radius: 10px;
  padding: 1em;
  font-family: iranyekan, "sans-serif";
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  top: -1rem;
  left: 150%;
}

.configs__tooltip:after {
  content: "";
  top: 100%;
  right: calc(var(--info-font-size) / 2);
  border: 1rem solid #0000;
  border-top-color: var(--nuetral-dark);
  transform: translateX(-.08rem) scale(var(--tooltip-scale));
  transform-origin: 100% 0;
}

.configs__info-radio {
  display: none;
}

.configs__info-radio:checked + .configs__info-icon + .configs__tooltip, .configs__info-radio:checked + .configs__info-icon + .configs__tooltip:after {
  opacity: 1;
  --tooltip-scale: 1;
}

.configs__tooltip-list {
  padding-top: 1rem;
  padding-right: 2rem;
}

.loading-page img {
  width: 13rem;
}

.login {
  width: 80%;
  max-width: 37.5rem;
  background-color: var(--primary-color-very-light);
  box-shadow: 0 1px 5px var(--primary-color-dark);
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 4rem;
  display: flex;
}

.login > * + * {
  margin-top: 2rem;
}

.login__photo {
  max-width: 30%;
  box-shadow: 0 1px 2px var(--primary-color-dark);
  border-radius: 50%;
}

.login__input {
  background-color: var(--nuetral-light);
  width: 75%;
  border-radius: 20px;
  position: relative;
}

.login__eye {
  cursor: pointer;
  font-size: 1.4rem;
  position: absolute;
  top: 50%;
  left: 1.5rem;
  transform: translateY(-50%);
}

.login__password, .login__btn {
  height: 4rem;
  box-shadow: 0 1px 2px var(--primary-color-dark);
  border: 0;
  border-radius: 20px;
  font-family: iranyekan, "sans-serif";
  font-size: 1.2rem;
  font-weight: 400;
  outline: none !important;
}

.login__password {
  width: 100%;
  background-color: #0000;
  padding-right: 1.5rem;
}

.login__btn {
  width: 75%;
  background-color: var(--primary-color);
  color: var(--nuetral-light);
  cursor: pointer;
  -webkit-tap-highlight-color: #0000;
  justify-content: center;
  align-items: center;
  transition: background-color .3s;
  display: flex;
}

.login__btn:active {
  background-color: var(--primary-color-very-dark);
}

.login__btn:disabled {
  background-color: var(--primary-color);
  cursor: wait;
}

.login__btn-loading {
  width: 2rem;
}

.login__btn-loading--hidden, .login__btn-text--hidden {
  display: none;
}

.login__wrong {
  color: red;
  font-family: iranyekan, sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
}

.login__wrong--hidden {
  visibility: hidden;
}

@media (hover: hover) and (pointer: fine) {
  .login__btn:hover {
    background-color: var(--primary-color-very-dark);
  }
}



/*# sourceMappingURL=index.59638a45.css.map */
