.configs__info-name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}

.configs__info {
    --info-font-size: 2.1rem;
    margin-left: auto;
    position: relative;
    cursor: pointer;
    outline: none !important;
    -webkit-appearance: none !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

.configs__name-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}


.configs__info-icon {
    font-size: var(--info-font-size);
}

.configs__tooltip,
.configs__tooltip::after {
    --tooltip-scale: 0;
    opacity: 0;
    position: absolute;
    z-index: 9;
    transition: all 0.3s;
}

.configs__tooltip {
    top: -1rem;
    left: 150%;
    width: max-content;
    max-width: calc(100vw / 1.5);
    transform: translate(-100%, -100%) scale(var(--tooltip-scale));
    transform-origin: bottom right;
    background-color: var(--nuetral-dark);
    color: white;
    padding: 1em;
    border-radius: 10px;
    font-size: 1.6rem;
    font-family: "iranyekan", "sans-serif";
    font-weight: 400;
    line-height: 1.5;
    text-align: right;
}

.configs__tooltip::after {
    content: "";
    top: 100%;
    right: calc(var(--info-font-size) / 2);
    border: 1rem solid transparent;
    border-top-color: var(--nuetral-dark);
    transform: translateX(-0.08rem) scale(var(--tooltip-scale));
    transform-origin: top right;
}

.configs__info-radio {
    display: none;
}

.configs__info-radio:checked + .configs__info-icon + .configs__tooltip,
.configs__info-radio:checked + .configs__info-icon + .configs__tooltip:after {
    opacity: 1;
    --tooltip-scale: 1;
}


.configs__tooltip-list{
    padding-right: 2rem;
    padding-top: 1rem;
}