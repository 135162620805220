.loading-page img{
    width: 13rem;
}
.login {
    width: 80%;
    max-width: 37.5rem;
    margin: 0 auto;
    padding: 4rem;
    background-color: var(--primary-color-very-light);
    box-shadow: 0 1px 5px var(--primary-color-dark);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/*every flex item except the first one*/
.login > * + * {
    margin-top: 2rem;
}

.login__photo {
    max-width: 30%;
    border-radius: 50%;
    box-shadow: 0 1px 2px var(--primary-color-dark);

}

.login__input {
    background-color: var(--nuetral-light);
    border-radius: 20px;
    position: relative;
    width: 75%;
}

.login__eye {
    position: absolute;
    font-size: 1.4rem;
    cursor: pointer;
    left: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
}

.login__password, .login__btn {
    height: 4rem;
    border: 0;
    outline: none !important;
    border-radius: 20px;
    box-shadow: 0 1px 2px var(--primary-color-dark);
    font-family: 'iranyekan', 'sans-serif';
    font-size: 1.2rem;
    font-weight: 400;
}

.login__password {
    width: 100%;
    background-color: rgba(0,0,0,0);
    padding-right: 1.5rem;
}


.login__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
    background-color: var(--primary-color);
    color: var(--nuetral-light);
    cursor: pointer;
    transition: background-color 0.3s;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}


.login__btn:active{
    background-color: var(--primary-color-very-dark);
}

.login__btn:disabled{
    background-color:  var(--primary-color);
    cursor: wait;
}

.login__btn-loading{
    width: 2rem;
}

.login__btn-loading--hidden{
    display: none;
}

.login__btn-text--hidden{
    display: none;
}

.login__wrong{
    font-family: 'iranyekan', sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    color: red;
}

.login__wrong--hidden{
    visibility: hidden;
}


@media (hover: hover) and (pointer: fine){
    .login__btn:hover{
        background-color: var(--primary-color-very-dark);
    }
}